import { useEffect, useRef } from "react";

const Advertisement = () => {
    const adRef = useRef(null);

    useEffect(() => {
        // Check if the ad script is loaded and if the current ad instance is not already initialized
        if (adRef.current && window.adsbygoogle && !adRef.current.isInitialized) {
            try {
                window.adsbygoogle.push({});
                adRef.current.isInitialized = true; // Mark this ad as initialized
            } catch (error) {
                console.error("Ad initialization error:", error);
            }
        }
    }, []);

    return (
        <div className="advertisementview mb-3">
            <div
                className="advertisementContainer"
                style={{
                    width: "100%",
                    height: "300px",
                    overflow: "hidden",
                    margin: "20px auto",
                }}
            >
                <ins
                    className="adsbygoogle"
                    style={{
                        display: "block",
                        width: "100%",
                        height: "100%",
                    }}
                    data-ad-layout-key="+2z+qi+5f-1m-98"
                    data-ad-client="ca-pub-8691062301030113"
                    data-ad-slot="4762943950"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                    ref={adRef}
                ></ins>
            </div>
        </div>
    );
};

export default Advertisement;
